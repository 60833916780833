import React from "react";
import '../../components/Layout/layoutGlossary.css';

const CustomSection = ({ children }) => {
  return (
    <div className="custom-section mtop-1">
      {children}
    </div>
  );
};

export default CustomSection;
